import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';

import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  AccountExpanded, QuestionTemplateRegistrationFilter,
  RegistrationData
} from '../../../../../../common/interfaces';
import { AccountService } from '../../../../services/account.service';

@Component({
  selector: 'update-profile-page',
  styleUrls: ['./update-profile-page.component.scss'],
  templateUrl: './update-profile-page.component.pug'
})
export class UpdateProfilePage implements OnInit {
  public templateFilter: number;
  public updateFilter: boolean;
  public barLabel: string = 'Password strength:';
  public passwordForm: FormGroup;
  private account: AccountExpanded;
  public showUpdateSuccessAlert: boolean;
  public showUpdateErrorAlert: boolean;
  public updateErrorMessage: string;
  public updateSuccessMessage: string;
  public showPasswordSuccessAlert: boolean;
  public showPasswordErrorAlert: boolean;
  public showPasswordMissingAlert: boolean;
  public isUnsubscribed: boolean;
  public hasResubscribed: boolean;
  public waiting: boolean;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private titleService: Title, private accountService: AccountService) {
    this.templateFilter = QuestionTemplateRegistrationFilter.None;
    this.updateFilter = true;
    this.hasResubscribed = false;
    this.waiting = false;
    this.isUnsubscribed = false;
    this.accountService.currentUser.pipe(
      tap(currentUser => {
        this.account = currentUser;
      }),
      switchMap(currentUser => {
        if (currentUser) {
          return this.accountService.getBlacklist(currentUser.id)
        } else {
          return of([])
        }
      })
    ).subscribe(
      (res) => {
        if (res.length > 0) {
          this.isUnsubscribed = true;
        }
      },
      err =>
        console.log(err)
    );
  }

  public ngOnInit() {
    this.passwordForm = this.fb.group({
      password: <string>null,
      newPassword: <string>null,
      confirmPassword: <string>null
    }, {
      validator: this.checkPasswords
    });

    this.titleService.setTitle('My Account');
  }

  public ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  public onPasswordFormSubmitted(newPassword: string, oldPassword: string) {
    this.showPasswordErrorAlert = false;
    this.showPasswordSuccessAlert = false;
    this.showPasswordMissingAlert = false;
    if (oldPassword !== undefined) {
      let userInfo: any = this.account;
      userInfo.password = oldPassword;
      userInfo.newPassword = newPassword;
      this.accountService.resetPassword(userInfo).subscribe(
        account => {
          this.showPasswordSuccessAlert = true;
        },
        error => {
          this.showPasswordErrorAlert = true;
        }
      );
    } else {
      this.showPasswordMissingAlert = true;
    }
  }

  public onRegistrationFormSubmitted(registrationData: RegistrationData) {
    this.showUpdateErrorAlert = false;
    this.showUpdateSuccessAlert = false;
    const roleMapping = this.account.RoleMapping;
    const country = this.account.Country;
    const minors = this.account.Minors;

    this.accountService.update(registrationData).subscribe(
      account => {
        account.Country = country;
        account.RoleMapping = roleMapping;
        account.Minors = account.Minors || minors;
        this.accountService.currentUser.next(account);
        this.showUpdateSuccessAlert = true;
        this.updateSuccessMessage = 'Success!';
      },
      err => {
        this.showUpdateErrorAlert = true;
        this.updateErrorMessage = err.message ? err.message : 'Could not update account info. Please try again later.';
      }
    );
  }

  public resubscribe(event?) {
    //event.target.blur(); //doesn't work here, fix later
    this.waiting = true;
    this.accountService.resubscribe(this.account.id).subscribe(
      res => {
        if (res.deleted) {
          this.isUnsubscribed = false;
          this.hasResubscribed = true;
        } else {
          throw res
        }
      },
      err => {
        console.log(err)
      },
      () => {
        this.waiting = false;
      }
    );
  }

  public unsubscribe(event) {
    event.target.blur();
    this.waiting = true;
    this.accountService.unsubscribe(this.account.id).subscribe(
      res => {
        if (res.added) {
          this.isUnsubscribed = true;
        } else {
          throw res
        }
      },
      err => {
        console.log(err)
      },
      () => {
        this.waiting = false;
      }
    );
  }

  private checkPasswords(group: FormGroup) {
    if (group.value.newPassword === group.value.confirmPassword) {
      return null;
    }
    return {
      passwordMismatch: true
    };
  }

  public emailData() {
    this.showUpdateSuccessAlert = false;
    this.showUpdateErrorAlert = false;
    this.accountService.requestData(this.account.id).subscribe(
      res => {
        if (res[0].status === 'sent' || res[0].status === 'queued') {
          this.showUpdateSuccessAlert = true;
          this.updateSuccessMessage = 'Account data sent to account email address.'
        } else {
          this.showUpdateErrorAlert = true;
          this.updateErrorMessage = 'Error emailing account data. Please try again later.'
          throw res
        }
      },
      err => {
        console.log(err)
      }
    );
  }

}
