import {
  ChangeDetectorRef, Component, DoCheck, Input,
  OnChanges, OnInit, QueryList, SimpleChanges, ViewChild,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldValidator } from '../../../common/validator';

import {
  AccountExpanded, Answer, Ethnicity, Gender, Guest, Kid, KidExpanded, Question,
  QuestionDataType, QuestionTemplateCode, RegistrationData
} from '../../../../../../common/interfaces';
import { ScreeningService, SharedService } from '../../../../services';
import { GuestForm, IndustryForm } from '../../forms';
import { BehaviorSubject, switchMap } from 'rxjs';

@Component({
  selector: 'screening-question-form',
  styleUrls: ['./screening-question-form.component.scss'],
  templateUrl: './screening-question-form.component.pug'
})
export class ScreeningQuestionForm implements OnInit, OnChanges, DoCheck {
  @Input() public account: AccountExpanded;
  @Input() public screening: any;
  @Input() public registration: any;
  @Input() public guests: Array<Guest>;
  @Input() public kids: Array<KidExpanded> = []
  @Input() public isEditing: boolean;
  @Input() public isPriority: boolean;
  @Input() public onlineFocusGroup: boolean;
  @Input() public showHighlightedError: boolean;
  @ViewChildren('gForms', { read: GuestForm }) public guestForms: QueryList<GuestForm>;
  @ViewChildren('kForms', { read: GuestForm }) public kidForms: QueryList<GuestForm>;

  @ViewChild(IndustryForm, { static: false }) public industryForm: IndustryForm;

  public form: FormGroup;
  public questions: Array<Question>;
  public industryQuestion: Question;
  public attendeeQuestions: Array<Question> = [];
  public attendeeAnswers: any = {};

  public guestQuestions: Array<Question>;
  public guestOptions: Array<any>;
  public children = Array<any>();
  public childQuestions: Array<Question>;
  public childOptions: Array<any>;
  public isGuestValid: boolean;
  public isKidValid: boolean
  public hiddenAgeQuestion: Question;
  public childCount: any;
  public guestCount: any;
  private changes$ = new BehaviorSubject(null)
  // public kids: KidExpanded[] = []

  constructor(public formBuilder: FormBuilder, private screeningService: ScreeningService,
    private cdr: ChangeDetectorRef, private sharedService: SharedService) { }

  public ngOnInit() {
    this.children = this.sharedService.getKids()
    this.form = this.formBuilder.group({});
    this.changes$.pipe(
      switchMap(() => {
        return this.screeningService.getRegistrationQuestions(this.screening.id)
      })
    ).subscribe(
      questions => this.updateQuestions(this.transformQuestions(questions)),
      err => console.log('Question error - ', err)
    );
  }

  // need to subscribe to changes so we don't try to load questions before screening is set
  // public ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
  //   console.log(changes);
  //   if (this.questions && this.questions.length > 0) { return; }
  //   // tslint:disable-next-line
  //   for (let propName in changes) {
  //     let changedProp = changes[propName];
  //     if (propName === 'screening' && changedProp.currentValue && changedProp.currentValue.id > 0) {
  //       this.getQuestions();
  //     }
  //   }
  // }

  ngOnChanges(changes: SimpleChanges): void {
    let isDataChanged = false;

    if (changes && changes['account'] && changes['account'].currentValue) {
      const currentId = changes['account'].currentValue.id;
      if (currentId > 0) {
        isDataChanged = true;
      }
    }

    if (changes && changes['screening'] && changes['screening'].currentValue) {
      const currentId = changes['screening'].currentValue.id;
      if (currentId > 0) {
        isDataChanged = true;
      }
    }

    if ((isDataChanged && this.screening && this.screening.id) || this.isEditing) {
      console.log("Re-Initianlize Screening Question Form");
      // this.getQuestions();
      this.changes$.next(null)
    }
  }

  public ngDoCheck() {
    let guestForms = this.guestForms;
    let kidForms = this.kidForms;
    let noKids = ((!this.children || this.children.length <= 0) && (this.kids && this.kids.length <= 0));
    let noGuests = (this.kids.length <= 0);
    if (!guestForms || guestForms.length <= 0 || noGuests) {
      this.isGuestValid = true;
    }
    if (!kidForms || kidForms.length <= 0 || noKids) {
      this.isKidValid = true
    }

    let validGuests = guestForms ? guestForms.filter((guestForm: any) => {
      return guestForm.form && guestForm.form.valid;
    }) : [];

    let validKids = kidForms ? kidForms.filter((kidForm: any) => {
      return kidForm.form && kidForm.form.valid
    }) : []

    this.isKidValid = (validKids && validKids.length) === (kidForms && kidForms != undefined && kidForms.length);
    this.isGuestValid = (validGuests && validGuests.length) === (guestForms && guestForms.length);
  }

  // show/hide askIf questions based on parent answer value
  public onAskIfChanged($event, type?) {
    if ($event.answer == undefined) return
    let parentId = $event.questionId;
    let newAnswer = $event.answer;
    let dataType = $event.dataType;

    if (type === 'guest' || type === 'child') {
      if (this.form.controls[parentId]) this.form.controls[parentId].setValue(newAnswer)
    }

    this.questions.forEach((linkedQuestion) => {
      const display = (linkedQuestion.configJSON.displayOptions || [])
      if (!!display.find(config => config.askIfId === parentId)) {
        let willShow = false;
        const show = display.map((config) => {
          let shouldShow;
          let answer = config.askIfId === parentId ? newAnswer : this.form.controls[config.askIfId] ? this.form.controls[config.askIfId].value : null;
          if (answer) {
            switch (config.askIfOperator) {
              case 'IS':
                if (dataType !== 1) {
                  shouldShow = answer === config.askIfValue;
                } else {
                  shouldShow = answer.toLowerCase() === config.askIfValue.toLowerCase();
                }
                break;
              case 'ISN\'T':
                if (dataType !== 1) {
                  shouldShow = answer !== config.askIfValue;
                } else {
                  shouldShow = answer.toLowerCase() !== config.askIfValue.toLowerCase();
                }
                break;
              case 'INCLUDES':
              case 'EXCLUDES':
                if (dataType !== 1) {
                  shouldShow = (config.askIfOption ? answer && answer[config.askIfOption] === config.askIfValue : answer && answer[config.askIfValue]) === !!(config.askIfOperator === 'INCLUDES');
                } else {
                  let found = answer.toLowerCase().search(config.askIfValue.toLowerCase()) > -1;
                  shouldShow = (found && config.askIfOperator === 'INCLUDES')
                    || (!found && config.askIfOperator !== 'INCLUDES');
                }
                break;
              case 'COUNT GREATER THAN':
              case 'COUNT LESS THAN':
                let values = Array.isArray(answer) ? answer.filter((value) => {
                  return value === true;
                }) : typeof answer === 'object' && answer !== null ? this.convertGridRuleSelector(answer, config.askIfListSelector).filter(option => option === config.askIfOption ? config.askIfOption : null) : [];
                if (config.askIfOperator === 'COUNT GREATER THAN') {
                  shouldShow = values.length > config.askIfValue;
                } else {
                  shouldShow = values.length < config.askIfValue;
                }
              default:
                break;
            }
          }
          return shouldShow
        })
        if (linkedQuestion.configJSON.displayOperator === "CUSTOM") {
          const condition = linkedQuestion.configJSON.customLogic || ""
          const accumulator = {
            level: 0,
            operator: [],
            not: [],
            value: [],
            match: show
          }
          try {
            const result: any = Array.from(condition.replace(/\s/g, '')).reduce((s: any, char, i, arr) => {
              switch (char) {
                case "&":
                case "|":
                  s.operator[s.level] = char
                  break;
                case "!":
                  s.not[s.level] = true
                  break;
                case "(":
                  s.level++;
                  break;
                case ")":
                  s.level--;
                case "$":
                  let val = char === "$" ? (!s.match.includes(undefined) && arr.join('').slice(i).match(/^[^\d]*(\d+)/) && s.match[parseInt(arr.join('').slice(i).match(/^[^\d]*(\d+)/)[1]) - 1]) : s.value.pop()
                  if (s.not[s.level]) {
                    val = !val
                    s.not[s.level] = false
                  }
                  if (typeof s.value[s.level] !== 'boolean') {
                    s.value[s.level] = val
                  } else if (s.operator[s.level] === "&") {
                    s.value[s.level] = s.value[s.level] && val
                  } else if (s.operator[s.level] === "|") {
                    s.value[s.level] = s.value[s.level] || val
                  }
                  break;
              }
              return s
            }, accumulator)
            willShow = !!result.value[0]
          } catch (err) {
            console.log("ERROR PARSING CUSTOM LOGIC", err)
          }
        } else {
          willShow = show.reduce((p, c) => (linkedQuestion.configJSON.displayOperator === "ALL" ? p && c : p || c))
        }
        if (!this.form.controls[linkedQuestion.formControlName]) { return; }
        if (linkedQuestion.isRequired) {
          let validators = willShow ? linkedQuestion.configJSON.dataType === QuestionDataType.CheckGroup ? FieldValidator.requiredCheckgroup : linkedQuestion.configJSON.dataType === QuestionDataType.Grid ? FieldValidator.requiredGridQuestion : Validators.required : null;
          this.form.controls[linkedQuestion.formControlName].setValidators(validators);
          this.form.controls[linkedQuestion.formControlName].updateValueAndValidity();
        }
        linkedQuestion.hidden = !willShow;
      }
    });
  }

  public invalidCount(number) {
    return !Number.isInteger(number)
  }

  public valid() {
    if (!this.screening) { return false; }
    if (this.onlineFocusGroup) { return true; }
    let missingGuestAnswer = (this.screening.maxGuests > 0 && this.guestCount !== undefined && isNaN(this.guestCount)) ||
      (this.screening.maxChildren > 0 && this.childCount !== undefined && isNaN(this.childCount));
    if (missingGuestAnswer) {
      this.isGuestValid = false;
    }
    let valid = this.isGuestValid;
    if (!this.isEditing) {
      valid = this.isGuestValid && (this.form && this.form.valid) && (this.industryForm ? !this.industryForm.isEmpty() : true) && this.isKidValid;
    }

    const gridQuestionIds = this.questions && this.questions.filter((que) => que && que.configJSON && que.configJSON.dataType && que.configJSON.dataType == 808).map(que => que.id.toString())
    if (gridQuestionIds && gridQuestionIds.length) {

      const gridControls = Object.entries(this.form.controls).filter(([id]) => gridQuestionIds.includes(id.toString()));
      if (gridControls && gridControls.length) {
        for (let i = 0; i < gridControls.length; i++) {
          const [_id, control] = gridControls[i];

          if (valid && control?.value && !Object.values(control.value).every(value => value !== undefined)) {
            valid = false
          }
        }
      }
    }
    return valid;
  }

  public values() {
    if (!this.account) {
      this.account = {};
    }
    let data: RegistrationData = {
      Account: {
        Kids: []
      },
      Answers: [],
      Guests: [],
      Kids: [],
      isIndustryValid: !this.industryForm || this.industryForm.valid()
    };
    if (this.questions) {
      this.questions.forEach(question => {
        let control = this.form.controls[question.id];
        let existing = this.attendeeAnswers[question.id]

        const ansMap = question.configJSON.answerMap || question.configJSON.answers || []
        const answerMap = (question.configJSON.answers || []).reduce((p, c, i) => (p[c] = ansMap[i], p), {})
        const optMap = question.configJSON.optionMap || question.configJSON.options || []
        const optionMap = (question.configJSON.options || []).reduce((p, c, i) => (p[c] = optMap[i], p), {})

        if (control.value || existing) {
          let values = control.value ? [] : existing.values || [];
          let mappedValues = control.value ? [] : existing.mappedValues || [];
          let checkgroup = question.configJSON && question.configJSON.dataType === QuestionDataType.CheckGroup;
          if (control.value) {
            if (checkgroup) {
              // make an array of selected values
              let obj = Object.keys(control.value).reduce((p, c) => {
                if (control.value[c] === true) p[c] = true;
                return p;
              }, {});
              values = Object.entries(obj);
              mappedValues = values.map(([k, v]: [string, string]) => ([answerMap[k], v]));
            } else {
              if (question.configJSON?.dataType === QuestionDataType.Grid) {
                mappedValues = [Object.fromEntries(Object.entries(control.value).map(([k, v]: [string, string]) => ([optionMap[k], answerMap[v]])))];
              } else {
                mappedValues.push(answerMap[control.value]);
              }
              values.push(control.value);
            }
          }

          // get answer data
          let answer: Answer = {
            id: existing ? existing.id : undefined,
            questionId: question.id,
            questionTemplateId: question.questionTemplateId,
            accountId: this.account.id,
            answerJSON: { values, mappedValues, label: question.label }
          };
          data.Answers.push(answer);

          // update gender/ethnicity properties
          if (question.fieldCode === QuestionTemplateCode.Ethnicity) {
            const current = existing?.value ? Array.isArray(existing.value) ? existing.value : [existing.value] : []
            const ethnicities = checkgroup ? (control?.value ? Object.entries(control.value).filter(([, y]) => !!y).map((([x]) => answerMap[x])) as Ethnicity[] : current) : (control?.value ? [answerMap[control.value]] : current);

            const accountEthnicities = this.account.ethnicities || (this.account.ethnicity ? [this.account.ethnicity] : [])
            // const removed = accountEthnicities.filter(eth => !ethnicities.includes(eth) && ansMap.includes(eth)).concat('Multi-Ethnic')
            
            const full = [...new Set([...accountEthnicities, ...ethnicities])].filter(eth => !!eth && eth !== 'Multi-Ethnic')
            this.account.ethnicities = full;
            data.Account.ethnicities = full;

            const ethnicity = (full.length > 1 ? 'Multi-Ethnic' : full[0]) || this.account.ethnicity
            this.account.ethnicity = ethnicity;
            data.Account.ethnicity = ethnicity;

          } else if (question.fieldCode === QuestionTemplateCode.Gender) {
            const gender = (control?.value && answerMap[control.value]) || existing?.value || this.account.gender
            this.account.gender = gender;
            data.Account.gender = gender;
          }
        }
      });
    }


    // data.Account = this.account;
    if (this.guestForms) {
      this.guestForms.map(
        guest => data.Guests.push(guest.values())
      );
    }

    if (this.kidForms) {
      this.kidForms.map(
        kid => data.Kids.push(kid.values())
      );

    }
    data.hiddenAgeQuestion = this.hiddenAgeQuestion;
    return data;
  }

  public onGuestCountChanged(guestCount) {
    if (guestCount < 1) {
      this.isGuestValid = true;
    }
    if (isNaN(guestCount)) {
      return;
    }
    this.guests.length = guestCount;
    this.guestCount = guestCount;
  }

  public onChildCountChanged(childCount: number) {
    if (childCount < 1) {
      this.isGuestValid = true;
    }

    if (isNaN(childCount)) {
      return;
    }
    this.children = new Array(childCount).fill('');
    this.childCount = childCount;
    if (this.kids.length > childCount) {
      this.kids.length = childCount;
    } else if (this.kids.length < childCount) {
      this.kids.push(...(new Array(childCount - this.kids.length).fill('')))
    }
    this.form.updateValueAndValidity();
    this.cdr.detectChanges();
  }

  // private getQuestions() {
  //   if (this.screening) {
  //     this.screeningService.getRegistrationQuestions(this.screening.id).subscribe(
  //       questions => this.updateQuestions(this.transformQuestions(questions)),
  //       err => console.log('Question error - ', err)
  //     );
  //   }
  // }

  private transformQuestions(questions) {
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];

      if (question.configJSON.displayOperator === 'EXPRESSION_BUILDER') {
        let newDisplayQuestions = [], expressionCount = 1, expressionString = '', length = question.configJSON.displayOptions.length;
        for (let ind = 0; ind < question.configJSON.displayOptions.length; ind++) {
          const que = question.configJSON.displayOptions[ind];

          if (que.askIfOption && Array.isArray(que.askIfOption) && que.askIfOption.length) {
            const {
              exprString,
              exprQuestions,
              expressionCountUpdated
            } = this.buildExpression(que.askIfOption, que.askIfValue, que.askIfId, que.askIfOperator, expressionCount, que.askIfListSelector || null)

            newDisplayQuestions.push(...exprQuestions);
            expressionString += `${exprString} ${ind !== length - 1 ? '&&' : ''} `;
            expressionCount = expressionCountUpdated;
          } else {
            newDisplayQuestions.push(que);
            expressionString += `$${expressionCount} ${ind !== length - 1 ? '&&' : ''} `;
            expressionCount++;
          }
        }

        question.configJSON.displayOperator = 'CUSTOM';
        question.configJSON.customLogic = expressionString;
        question.configJSON.displayOptions = newDisplayQuestions;
      }

    }
    return questions;
  }

  private buildExpression(answers, val, askIfId, askIfOperator, expressionCount, askIfListSelector) {
    let inputArr = [];
    if (askIfOperator.toLowerCase() === 'count greater than') {
      for (let i = val; i > -1; i--) {
        inputArr.push(i);
      }
    } else {
      for (let i = val; i >= 0; i--) {
        inputArr.push(i);
      }
    }

    let strArr = [];
    for (let s = 0; s < answers.length; s++) {
      strArr[s] = [];
      for (let i = val; i > -1; i--) {
        strArr[s].push({ ans: answers[s], value: i });
      }
    }

    let output = this.generatePermutations(val, strArr, askIfOperator);

    output = output.map((arr) => {
      arr = arr.map((obj) => {
        let askIfValue;
        if (askIfOperator.toLowerCase() === 'count greater than') {
          askIfValue = obj.value - 1
        } else {
          askIfValue = obj.value + 1
        }
        return {
          askIfId,
          askIfOperator,
          askIfValue: askIfValue,
          askIfOption: obj.ans,
          askIfListSelector: askIfListSelector && Array.isArray(askIfListSelector) ? askIfListSelector : []
        };
      });
      return arr;
    });
    const { exprString, expressionCountUpdated } = this.buildExpressionString(output, expressionCount);

    let exprQuestions = [];
    output.forEach(element => {
      exprQuestions.push(...element)
    });
    return {
      exprString: "(" + exprString + ")",
      exprQuestions,
      expressionCountUpdated
    }
  }

  private buildExpressionString(payload, expressionCount) {
    const comboSetOr = payload.map((arrElement) => {
      return arrElement.map((_) => {
        return `$${expressionCount++}`;
      });
    });
    const expression = comboSetOr
      .map((inner) => "(" + inner.join(" && ") + ")")
      .join(" || ");

    return { exprString: expression, expressionCountUpdated: expressionCount };
  }

  private generatePermutations(
    inputValue,
    data,
    askIfOperator,
    currentIndex = 0,
    currentPermutation = [],
    output = []
  ) {
    if (currentIndex === data.length) {
      if (askIfOperator.toLowerCase() === 'count greater than') {
        if (inputValue === 0) {
          output.push([...currentPermutation]);
        }
      } else {
        output.push([...currentPermutation]);
      }
      return;
    }

    for (let i = 0; i < data[currentIndex].length; i++) {
      if (data[currentIndex][i].value <= inputValue) {
        currentPermutation[currentIndex] = {
          ans: data[currentIndex][i].ans,
          value: data[currentIndex][i].value,
        };
        this.generatePermutations(
          inputValue - data[currentIndex][i].value,
          data,
          askIfOperator,
          currentIndex + 1,
          currentPermutation,
          output
        );
      }
    }

    return output;
  }

  private updateQuestions(questions: Array<Question>) {
    console.log("UPDATING QUESTIONS!")
    let validQuestions = questions.filter((question: Question) => {

      if (question.fieldCode === QuestionTemplateCode.FieldsOfEmployment) {
        this.industryQuestion = question;
        return false;
      }

      if (!question) { return; }
      let config: any = question.configJSON;
      if (!config) { return false; }

      let hasAnswers = config.answers && config.answers.length > 0;
      if (!hasAnswers && config.dataType !== QuestionDataType.Text) { return false; }

      // if age question is hidden, we need to generate one based on account birthdate
      if (question.fieldCode === QuestionTemplateCode.Age && !question.isForAttendee) {
        this.hiddenAgeQuestion = question;
      }
      if (!question.isRequired) {
        question.displayQuestion += ' (optional)';
      }

      if (config.display !== 'Always' && config.displayOptions && config.displayOptions.length) {
        question.hidden = true;
        config.displayOptions.forEach((option) => {
          let linkedQuestion = questions.find((q) => {
            return q.id === option.askIfId;
          });
          // set shouldEmit so the question knows to emit changes
          linkedQuestion.configJSON.shouldEmit = true;
        })
      }

      return true;
    });
    this.setupGuests(validQuestions);
    this.questions = validQuestions;

    if ((this.isEditing || this.onlineFocusGroup) && this.registration && this.registration.Answers && this.registration.Answers.length) {
      this.registration.Answers.forEach((answer) => {
        if (answer.accountId === this.registration.accountId && answer.answerJSON && answer.answerJSON.values[0]) {
          let value = answer.answerJSON.values
          let mappedValues = answer.answerJSON.mappedValues
          this.attendeeAnswers[answer.questionId] = {
            id: answer.id,
            value: Array.isArray(value[0]) ? value.filter(x => x[1]).map(x => x[0]) : value[0],
            values: value,
            mappedValues
          }
        }
      })
    }

    // let Answers = [];
    // this.questions.forEach((q: any) => {
    //   if (q.Answers && q.Answers.length) {
    //     Answers.push(...q.Answers)
    //   }
    // });

    // if (Answers.length) {
    //   Answers.forEach((answer) => {
    //     if (this.account && this.account.id === answer.accountId && answer.answerJSON && answer.answerJSON.values[0]) {
    //       let value = answer.answerJSON.values;
    //       if (!this.attendeeAnswers[answer.questionId]) {
    //         this.attendeeAnswers[answer.questionId] = {
    //           value: Array.isArray(value[0]) ? value.filter(x => x[1]).map(x => x[0]) : value[0],
    //           values: value
    //         }
    //       }
    //     }
    //   });
    // }

    // get age from birthdate
    let age;
    if (this.account && this.account.birthDate) {
      const today = new Date();
      const birthDate = new Date(this.account.birthDate.toString()) //to prevent type errors
      age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
    }

    let group: any = {};
    if (this.questions) {
      this.questions.forEach(question => {
        // set empty answer for freeform text questions to prevent 'undefined' in textarea
        let q: any = question;
        const ansMap = question.configJSON.answerMap || question.configJSON.answers || []
        const answerMap = ansMap.reduce((p, c, i) => (p[c] = (question.configJSON.answers || [])[i], p), {})
        if (q.configJSON.dataType === 1) {
          q.answer = this.attendeeAnswers[q.id]?.value || q.answer || '';
        } else if (this.attendeeAnswers[q.id]) {
          if (Array.isArray(this.attendeeAnswers[q.id].value) && q.configJSON.dataType === 408) {
            q.answer = Object.fromEntries(this.attendeeAnswers[q.id].values)
          } else {
            q.answer = this.attendeeAnswers[q.id].value || null
          }
        }
        if (age && q.fieldCode === QuestionTemplateCode.Age) {
          const ageRange = q.configJSON.answers.find(answer => {
            const range = answer.replace(/([^-\S\d])/g, '').replace(/\+/g, '-99').split('-').map(s => parseFloat(s))
            return range && ((age - range[0]) * (age - range[1]) <= 0);
          })
          if (ageRange) q.answer = ageRange
        } else if (!q.answer && q.fieldCode === QuestionTemplateCode.Gender && this.account?.gender) {
          q.answer = answerMap[this.account.gender]
        } else if (!q.answer && q.fieldCode === QuestionTemplateCode.Ethnicity && (this.account?.ethnicity || this.account?.ethnicities)) {
          const ans = question.configJSON.dataType === QuestionDataType.CheckGroup ? this.account?.ethnicities || (this.account.ethnicity ? [this.account.ethnicity] : null) : this.account?.ethnicities?.[0] || this.account.ethnicity
          const filtered = Array.isArray(ans) ? ans.filter(x => x && ansMap.includes(x)) : ans
          if (ans) q.answer = Array.isArray(filtered) ? filtered.length ? Object.fromEntries(filtered.map(x => ([answerMap[x], true]))) : null : ansMap.includes(filtered) ? answerMap[filtered] : null
        }
        q.formControlName = q.id;
        // potentially unneeded, as validators are set in the question component on init
        if (!question.isRequired || question.hidden || this.isEditing || !question.isForAttendee) {
          group[q.formControlName] = new FormControl(q.answer || null);
        } else {
          const validator = q.configJSON.dataType === QuestionDataType.CheckGroup ? FieldValidator.requiredCheckgroup : q.configJSON.dataType === QuestionDataType.Grid ? FieldValidator.requiredGridQuestion : Validators.required
          group[q.formControlName] = new FormControl(q.answer || null, validator);
        }
      });
    }

    this.attendeeQuestions = validQuestions.filter((question: Question) => {
      const fgCheck = this.onlineFocusGroup ? !Object.keys(this.attendeeAnswers).includes(`${question.id}`) : true
      return fgCheck && (this.isPriority ? (question.isForAttendee && question.isForPriorityAttendee) : question.isForAttendee);
    });
    this.form = this.formBuilder.group(group);
  }

  private setupGuests(validQuestions: any) {
    // regular guests
    if (this.screening.maxGuests > 0 || this.screening.minGuests > 0) {
      const guestQuestions = validQuestions.filter((question: any) => {
        return this.isPriority ? (question.isForGuest && question.isForPriorityGuest) : question.isForGuest;
      });
      this.guestQuestions = guestQuestions.map(x => structuredClone(x));
      if (this.guestQuestions && Array.isArray(this.guestQuestions) && this.guestQuestions.length) {
        this.guestQuestions = this.guestQuestions.map((question: any) => {
          if (["Ethnicity", "Gender"].includes(question.fieldCode)) {
            question.answer = null;
          }
          return question;
        })
      }
      let max = Math.max(this.screening.maxGuests, this.screening.minGuests);
      let min = this.screening.minGuests || 0;
      this.guestOptions = this.numberArrayFromCount(max + 1, min);
      this.onGuestCountChanged((this.guests && this.guests.length) || min);
    }
    // children
    if (this.screening.maxChildren > 0 || this.screening.minChildren > 0) {
      let max = Math.max(this.screening.maxChildren, this.screening.minChildren);
      let min = this.screening.minChildren || 0;
      const childQuestions = validQuestions.filter((question: any) => {
        return question.isForChild ? true : false
      });
      this.childQuestions = childQuestions.map(x => structuredClone(x));
      this.childOptions = this.numberArrayFromCount(max + 1, min);
      this.onChildCountChanged((this.kids && this.kids.length) || min);
    }
  }

  private numberArrayFromCount(count: number, min: number = 0) {
    let array = new Array<number>();
    for (let i = min; i < count; i++) {
      array.push(i);
    }
    return array;
  }

  // private mapEthnicity(answer) {
  //   const defaults = {
  //       "African-American/Black": /\b(Afr|Black)/i,
  //       "Asian": /\b(Asian|Pacific)/i,
  //       "Caucasian/White": /\b(Caucasian|White)/i,
  //       "Hispanic/Latino": /\b(Hispanic|Latino)/i,
  //       "Native American": /\b(Native)/i,
  //       "Some Other Race": /\b(Other)/i,
  //   }
  //   const value = Object.entries(defaults).find(([val, regex]) => regex.test(answer))
  //   return value ? (value[0] as Ethnicity) : null
  // }

  // private mapGender(answer) {
  //   const defaults = {
  //       "Male": /\b(Male|Boy|Man)/i,
  //       "Female": /\b(Female|Girl|Woman)/i,
  //   }
  //   const value = Object.entries(defaults).find(([val, regex]) => regex.test(answer))
  //   return value ? (value[0] as Gender) : null
  // }

  convertGridRuleSelector(values, ruleListSelector): any {
    let tempValue = Array.isArray(values) && values.length ? values[0] : values;
    tempValue = tempValue || {}

    return ruleListSelector && ruleListSelector.length ? Object.values(Object.entries(values).filter(([key, value]) => ruleListSelector.includes(key)).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {})) : Object.values(values)
  }
}
