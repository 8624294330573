import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import {
  AccountExpanded, AccountRoleType,
  QuestionTemplate, QuestionTemplateRegistrationFilter,
  RegistrationData
} from '../../../../../../common/interfaces';
import { AccountService, QuestionTemplateService } from '../../../../services';

import { distinctUntilChanged } from 'rxjs/operators';
import { QuestionTemplateForm } from '../../forms';

declare const ga: Function;

@Component({
  selector: 'registration-optional-page',
  styleUrls: ['./registration-optional-page.component.scss'],
  templateUrl: './registration-optional-page.component.pug'
})
export class RegistrationOptionalPage implements OnInit {
  @ViewChild(QuestionTemplateForm, { static: false }) public optionalForm: QuestionTemplateForm;

  public templateForm: FormGroup;
  public templates: Array<QuestionTemplate>;
  public templateFilter: number;
  public logId: number;
  public barLabel: string = 'Password strength:';
  public isChildValid: boolean;
  public showSuccessAlert: boolean;
  public showErrorAlert: boolean;
  public waiting: boolean = false;

  private account: AccountExpanded;

  constructor(private titleService: Title, private accountService: AccountService, private route: ActivatedRoute, private questionTemplateService: QuestionTemplateService, private router: Router) {
    this.templateFilter = QuestionTemplateRegistrationFilter.Optional;
    this.accountService.currentUser.subscribe(
      currentUser => this.account = currentUser
    );
    this.questionTemplateService.get(this.templateFilter).subscribe(
      templates => this.templates = templates,
      err => console.log('QuestionTemplate error - ', err)
    );
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      if (current instanceof NavigationEnd) {
        return previous.url === current.url;
      }
      return true;
    })).subscribe((navigation: any) => {
      ga('set', 'page', navigation.url);
      ga('send', 'pageview');
    });
  }

  public ngOnInit() {
    this.titleService.setTitle('Registration Continued');
    this.route.queryParams.subscribe(params => {
      if (params['logId']) {
        this.logId = params['logId']
      }
    });
  }

  public onFormSubmitted(form: any, optionalRegistrationData: any, password: any) {
    if (!this.account) {
      this.showErrorAlert = true;
      form.reset();
      return;
    }
    let data: RegistrationData = optionalRegistrationData;
    if (password) {
      data.password = password;
      data.email = this.account.email;
    }
    this.waiting = true;
    this.accountService.updateRegistration(data).subscribe(
      res => {
        if (this.logId) {
          this.accountService.updateRecruiterLog(this.logId, { status: "Validation" }).subscribe(
            (account) => {
              if (
                res.RoleMapping &&
                res.RoleMapping.roleId === AccountRoleType.User
              ) {
                //  user signed up
                this.router.navigate(["/registration/confirmation"], {
                  queryParams: { email: data.email, logId: this.logId },
                });
              }
            },
            (error) => {
              this.showErrorAlert = true;
              form.reset();
            }
          );
        } else if (res.RoleMapping && res.RoleMapping.roleId === AccountRoleType.User) {
          //  user signed up
          this.router.navigate(["/registration/confirmation"], {
            queryParams: { email: data.email },
          });
        }
        form.reset();
        this.showSuccessAlert = true;
      },
      error => {
        this.showErrorAlert = true;
        form.reset();
      }, 
      () => {
        this.waiting = false;
      }
    );
  }
}
