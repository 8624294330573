import { Kid, KidExpanded, MinorExpanded } from '.';
import { BaseModel } from './base';
import { QuestionExpanded } from './question';

type Gender = 'Male' | 'Female';
type KidGender = 'Boy' | 'Girl';
type Ethnicity = 'Black/African-American' | 'Caucasian/White' | 'Asian' | 'Hispanic/Latino'  |
                'Native American' | 'Other';
type AccountStatus = 'Active' | 'Inactive' | 'Banned' | 'Industry Banned';
type PhoneType = 'Mobile' | 'Home' | 'Work';
type PrimaryLanguage = 'English' | 'Spanish' | 'Other';
type CalendarMonth = 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' |
                    'September' | 'October' | 'November' | 'December';


// tslint:disable
const GENDER_OPTIONS: Array<Gender | string> = ['Male', 'Female'];
const KID_GENDER_OPTIONS: Array<KidGender> = ['Boy', 'Girl'];
const ETHNICITY_OPTIONS: Array<Ethnicity | string> = [ 'Black/African-American', 'Caucasian/White', 'Asian', 'Hispanic/Latino', 'Native American', 'Other'];
const CALENDAR_MONTH_OPTIONS: Array<CalendarMonth> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
                    'September', 'October', 'November', 'December'];
// tslint:enable

enum AccountRoleType {
  User = 1, Recruiter, Administrator, Anonymous, Limited, Manager, SuperAdmin
}

interface AccessToken {
   id?: string;
   ttl?: string;
   created?: Date;
}

interface AccountData {
  recruiterId?: number;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date | string;
  gender?: Gender | string;
  ethnicity?: Ethnicity | string;
  ethnicities?: Ethnicity[] | string[];
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  countryId?: number;
  Country?: any;
  phoneNumber?: string;
  phoneType?: PhoneType;
  smsEnabled?: boolean;
  isParent?: boolean;
  username?: string;
  primaryLanguage?: PrimaryLanguage;
  hasRegisteredPFMWebsite?: boolean;
  hasConfirmedPFMScreening?: boolean;
  isPFMRecruiter?: boolean;
  unsubscribe?: boolean;
  twitter?: string;
  instagram?: string;
  showRate?: number;
  preferredContactInvite?: string;
  preferredContactReminder?: string;
  internalNotes?: string;
  isPFMRecruitEligible?: boolean;
}

interface Account extends AccountData, BaseModel {
  emailVerified?: boolean;
  status?: AccountStatus;
  roleId?: AccountRoleType;
  RoleMapping?: any;
  isExternal?: boolean
}

interface AccountExpanded extends Account {
  Questions?: QuestionExpanded[];
  Kids?: KidExpanded[]
  Minors?: MinorExpanded[]
}

export { AccessToken, Account, AccountData, Gender, Ethnicity, PhoneType,
  AccountStatus, AccountExpanded, AccountRoleType, GENDER_OPTIONS, KID_GENDER_OPTIONS, ETHNICITY_OPTIONS, CALENDAR_MONTH_OPTIONS }
