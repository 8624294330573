import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { AccessToken } from '../../../common/interfaces';
// import { ConfigService } from './config.service';
import { HttpHeaders, HttpParams } from "@angular/common/http";

enum HTTPMethod {
  GET,
  PUT,
  PATCH,
  POST,
  DELETE
}

interface ISuccessResponse {
  success?: boolean;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
class NetworkService {
  public accessToken: AccessToken = { id: undefined, ttl: undefined, created: undefined };
  public loggedIn: boolean;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.verifyAuthInfo();
  }

  public httpRequest<T>(method: HTTPMethod, path: string, params: Object = {}, tokenId?: string): Observable<T> {
    let url = '/api/' + path;
    let headers = new HttpHeaders()
    let urlParams;
    if (this.accessToken && this.accessToken.id && !tokenId) {
      headers = headers.append("Authorization", this.accessToken.id)
    } else if (tokenId) {
      headers = headers.append("Authorization", tokenId)
    }

    switch (method) {
      default:
      case HTTPMethod.GET:
        // create search parameters for GET and DELETE
        urlParams = this.makeUrlSearchParams(params);
        return this.http.get<T>(url, { headers, params: urlParams }).pipe(tap(this.saveAuthInfo));

      case HTTPMethod.DELETE:
        urlParams = this.makeUrlSearchParams(params);
        return this.http.delete<T>(url, { headers, params: urlParams }).pipe(tap(this.saveAuthInfo));

      case HTTPMethod.PUT:
        // put parameters in body for PUT and POST
        return this.http.put<T>(url, params, { headers }).pipe(tap(this.saveAuthInfo));
      
      case HTTPMethod.PATCH:
        // put parameters in body for PUT and POST
        return this.http.patch<T>(url, params, { headers }).pipe(tap(this.saveAuthInfo));

      case HTTPMethod.POST:
        return this.http.post<T>(url, params, { headers }).pipe(tap(this.saveAuthInfo));
    }
  }

  public uploadFile(url, file) {
    return this.http.put(url, file);
  }

  public clearAuthInfo = () => {
    this.cookieService.delete('accessToken', '/');
    this.accessToken = { id: undefined, ttl: undefined, created: undefined };
    this.cookieService.delete('okta_token', '/');
    this.cookieService.delete('username', '/');
    this.cookieService.delete('userId', '/');
    this.cookieService.delete('session', '/');
    this.cookieService.delete('roles', '/');
  }

  public verifyAuthInfo = () => {
    this.accessToken.id = this.cookieService.get('accessToken');
  }

  private saveAuthInfo = (data) => {
    if (data && data.accessToken) {
      if (!this.accessToken) { this.accessToken = {}; };
      this.accessToken.id = data.accessToken;
      this.accessToken.ttl = data.accessTokenTTL;
      this.accessToken.created = data.accessTokenCreated;
      this.cookieService.set('accessToken', data.accessToken, 0.375); // expire in 9 hours
    }
  }

  private makeUrlSearchParams(params: Object): HttpParams {
    let searchParams = new HttpParams();
    Object.entries(params).forEach(([key, value]) => {
      searchParams = searchParams.append(key, value);
    })
    return searchParams;
  }

  // private extractJSON = (res: Response) => {
  //   if (res.status === 204) {
  //     return res;
  //   }
  //   let data = res.json();
  // if (data && data.accessToken) {
  //   if (!this.accessToken) { this.accessToken = {}; };
  //   this.accessToken.id = data.accessToken;
  //   this.accessToken.ttl = data.accessTokenTTL;
  //   this.accessToken.created = data.accessTokenCreated;
  //   this.saveAuthInfo();
  // }
  //   return data;
  // }

  // private handleHttpError = (error: Response) => {
  //   let err: StatusError = error.json().error;
  //   return Observable.throw(err);
  // }
}

export { HTTPMethod, ISuccessResponse, NetworkService };

