import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';

import { RadioInput } from '../../form-controls';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { RegistrationService } from '../../../../services';

@Component({
  selector: 'select-time-modal',
  styleUrls: ['./select-time-modal.component.scss'],
  templateUrl: './select-time-modal.component.pug'
})
export class SelectTimeModal {
  @ViewChildren(RadioInput) public radios: QueryList<RadioInput>;
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();

  public registration: any;
  public screeningTimes: any[];
  public screeningId: number;
  public registrationId: number;

  constructor(private registrationService: RegistrationService, public dialog: BsModalRef) { }

  ngOnInit() {
    if (this.registration) {
      this.screeningId = this.registration.screeningId;
      this.registrationId = this.registration.id;
    }
  }


  public onItemChecked(id: any) {
    this.screeningId = id;
  }

  public onKeyUp() {
    this.close();
  }

  public onConfirm() {
    this.registrationService.changeScreening(this.registrationId, this.screeningId).subscribe(
      (registration) => {
        this.close(registration)
      },
      (err) => {
        this.close();
      }
    );
  }

  public onCancel() {
    this.registrationService.cancel(this.registrationId).subscribe(
      (registration) => {
        this.close();
      },
      (err) => {
        this.close();
      }
    );
  }

  private close(result?) {
    this.onSubmit.emit(result);
    this.dialog.hide();
  }

}
