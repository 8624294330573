import { Component, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { CanComponentDeactivate } from '../../../common/guards/pending-changes.guard';

import { AccountService, RegistrationService, ScreeningService, SharedService } from '../../../../services';

import {
  Question,
  QuestionTemplateCode, RegistrationExpanded, ScreeningExpanded
} from '../../../../../../common/interfaces';

import moment from 'moment';
import 'moment-timezone';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IndustryForm } from '../../forms';

@Component({
  selector: 'pre-check-confirmation-page',
  styleUrls: ['./pre-check-confirmation-page.component.scss'],
  templateUrl: './pre-check-confirmation-page.component.pug'
})
export class PreCheckConfirmationPage implements OnInit, CanComponentDeactivate {

  @ViewChild(IndustryForm, { static: false }) public industryForm: IndustryForm;

  public isEditing: boolean;
  public industryQuestion: Question;
  public submitted: boolean;
  public screeningAddress: string = '';
  public screeningLocation: string = '';
  public registration: RegistrationExpanded;
  public screening: ScreeningExpanded;
  private accessTokenId: string;
  private encryptedId: string;
  private screeningId: number;
  public attendeeQuestions: Array<Question> = [];
  public questions: Array<Question>;
  public form: FormGroup;
  public loading: boolean = true;
  public waiting: boolean = false;
  public userTimezone: string;

  constructor(public screeningService: ScreeningService, private registrationService: RegistrationService, private route: ActivatedRoute,
    private router: Router, private sharedService: SharedService, private titleService: Title, public accountService: AccountService,
    private formBuilder: FormBuilder) { }

  public ngOnInit() {
    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    this.accessTokenId = accessTokenId;
    this.titleService.setTitle('Pre Check-In');
    let id = this.route.snapshot.params['id'];
    this.screeningId = this.route.snapshot.params['screeningId'];
    this.userTimezone = moment.tz.guess();
    this.encryptedId = id;
    this.registrationService.getByIdCheckIn(id, this.screeningId).pipe(
      switchMap(data => {
        this.registration = data;
        const res: {
          status: string,
          questions: Question[]
        } = { status: 'sent', questions: [] }
        if (moment() >= moment.utc(data.Screening.screeningStartDate)) {
          res.status = 'past'
        } else if (!data.ndaSent || (data.ndaSent && !data.ndaSignature)) {
          this.screening = data.Screening;
          if (data.ScreeningInvitation) {
            this.screening.title = data.ScreeningInvitation.isBlind ? 'Movie Preview' : data.ScreeningInvitation.title
          }
          this.setScreeningData(this.screening);
          return this.screeningService.getRegistrationQuestions(this.screening.id).pipe(
            tap(qx => res.questions = qx),
            switchMap(() => of(res))
          )
        }
        return of(res)
      })
    ).subscribe((resp) => {
      if (resp.status === 'past') {
        this.sharedService.setRegistrationError({
          errorTitle: 'Registration Date Past',
          pageTitle: 'Register Error',
          message: 'Sorry, but the registration date for this event has past.'
        });
        this.router.navigate(['registration-error']);
      } else if (resp.status === 'sent') {
        this.router.navigate(['/screenings', this.screeningId, 'nda', this.encryptedId], { queryParams: { [TOKEN_KEY]: this.accessTokenId } });
      } else {
        resp.questions.forEach((question) => {
          if (question.fieldCode === QuestionTemplateCode.FieldsOfEmployment) {
            this.industryQuestion = question;
          }
        })
      }
    }, err => {
      console.log(err)
      if (err.status === 404) this.router.navigate(['/not-found']);
    }, () => {
      this.loading = false;
    })
    this.form = this.formBuilder.group({});
  }

  // need to subscribe to changes so we don't try to load questions before screening is set
  public ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.questions && this.questions.length > 0) { return; }
    // tslint:disable-next-line
    for (let propName in changes) {
      let changedProp = changes[propName];
      if (propName === 'screening' && changedProp.currentValue && changedProp.currentValue.id > 0) {
        this.screeningService.getRegistrationQuestions(this.screening.id).subscribe((questions: Question[]) => {
          questions.forEach((question) => {
            if (question.fieldCode === QuestionTemplateCode.FieldsOfEmployment) {
              this.industryQuestion = question;
            }
          })
        })
      }
    }
  }

  private setScreeningData(screening: ScreeningExpanded) {
    this.sharedService.setScreeningRegistration(this.registration);
    this.titleService.setTitle('Pre Check In - ' + screening.title);
    this.screeningAddress = this.sharedService.getScreeningAddress(screening);
    this.screeningLocation = this.sharedService.getScreeningLocation(screening);
  }

  public onFormSubmitted(industryBanned: boolean) {
    this.waiting = true;
    let data = this.registration;
    let sent = false;
    this.registrationService.sendNDA(this.encryptedId, this.screeningId, this.accessTokenId).pipe(
      tap((resp: any) => {
        sent = resp.ndaSent
      }),
      switchMap((resp: any) => {
        if (industryBanned) {
          data.Account.status = 'Industry Banned'
          return this.accountService.industryBan(data)
        } else {
          return of(resp)
        }
      })
    ).subscribe((resp) => {
      if (sent) {
        this.router.navigate(['/screenings', this.screeningId, 'nda', this.encryptedId]);
      } else {
        this.waiting = false;
      }
    },
      (err) => {
        this.waiting = false;
        console.log(err)
      });
  }
}
