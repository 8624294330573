import { Component, Input, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { RadioInput } from '../radio-input';

@Component({
  selector: 'grid-question',
  styleUrls: ['./grid-question.component.scss'],
  templateUrl: './grid-question.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GridQuestion),
    multi: true
  }]
})
export class GridQuestion implements OnInit {
  @ViewChildren(RadioInput) public radios: QueryList<RadioInput>;
  @Input() public answers = new Array<string>();
  @Input() public options = new Array<string>();
  @Input() public answerText: string[] = [];
  @Input() public optionText: string[] = [];
  @Input() public style: string;
  @Input() public value: any;
  @Input() public questionId: any;
  @Input() public guestNumber: any;
  @Input() public isInvalid: any;
  @Input() public showHighlightedError: boolean;

  public ngOnInit() {
    this.value = this.options.reduce((p, c) => (p[c] = "", p), {});
  }

  public writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  public onItemChecked(item: any) {
    this.value[item.option] = item.answer;
    this.propagateChange(this.value); // delete line?
  }

  public propagateChange = (_: any) => { }; // delete line?

  public registerOnChange(fn: any) { // delete function?
    this.propagateChange = fn;
  }

  public registerOnTouched() { } // delete line?

  public ngDoCheck() { // delete function?
    if (this.radios) {
      // update value (check-state) of checkbox based on input value
      this.radios.forEach((radio: any) => {
        radio.selected = this.value[radio.option];
      });
    }
  }
}
