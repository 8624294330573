import { AbstractControl, FormControl } from '@angular/forms';

interface ValidationResult {
  [key: string]: boolean;
}
interface ValidatorFn { (c: AbstractControl): { [key: string]: any }; }
export class FieldValidator {

  public static requiredCheckgroup(control: AbstractControl): ValidationResult {
    const reducedValue = control.value ? Object.values(control.value).reduce((a, b) => a || b, false) : false;
    return reducedValue ? null : { emptyCheckgroup: true }
  }
  
  public static requiredGridQuestion(control: FormControl): ValidationResult {
    const answer = control.value;
    let answerValid = false;
    if (answer && typeof answer === 'object') {
      const allValuesDefined = Object.values(answer).every(value => !!value);
      if (allValuesDefined) {
        answerValid = true
      } else {
        answerValid = false
      }
    } else {
      answerValid = !!answer
    }
    return answerValid ? null : { emptyGridQuestion: true }
  }

  public static emailFormat(control: AbstractControl): ValidationResult {
    /* tslint:disable-next-line */
    const EMAIL_REGEXP =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (control.value && control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { incorrectMailFormat: true };
    }
    return null;
    // const isValid = this.validateEmail(control.value)
    // console.log(control.value)
    // console.log(isValid)
    // return { incorrectMailFormat: !isValid };
  }

  public static validEmail(email: string): boolean {
    /* tslint:disable-next-line */
    const EMAIL_REGEXP =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (email && email !== '' && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
      return false;
    }
    return true;
  }

  public static validateName(control: AbstractControl): ValidationResult {
    /* tslint:disable-next-line */
    const NAME_REGEXP = /^[a-zA-Z\s-]*$/;
    if (control.value) {
      if (!NAME_REGEXP.test(control.value)) {
        return { incorrectNameFormat: true };
      }
    }

    return null;
  };

  public static validatePhoneNumber(control: AbstractControl): ValidationResult {
    /* tslint:disable-next-line */
    const PHONE_REGEXP = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (control.value) {
      let phoneNumber = control.value.replace(/\D+/g, '');
      if (length > 15) {
        phoneNumber.value = phoneNumber.substring(0, 15);
      }
      if (!PHONE_REGEXP.test(phoneNumber)) {
        return { incorrectPhoneFormat: true };
      }
    }

    return null;
  };

  public static validateZipCode = (regex?: string, countryId?: number) => {
    return (control: FormControl): ValidationResult => {
      let _regex = regex ? new RegExp(regex) : /^\b\d{5}\b(?:[- ]{1}\d{4})?$/;
      if (countryId === 241) {
        _regex = regex ? new RegExp(regex, 'gi') : /^\b\d{5}\b(?:[- ]{1}\d{4})?$/;
      }
      let valid = _regex.test(control.value);

      if (valid && countryId === 241) {
        control.value.replace(/\s/g, '');
        if (_regex.test(control.value)) {
          return null;
        }
      }

      if (valid) {
        return null;
      }

      return { incorrectPostalFormat: true };
    };
  }
}
