import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RegistrationExpanded } from '../../../common/interfaces/registration';
import { HTTPMethod, ISuccessResponse, NetworkService } from './network.service';

@Injectable()
class RegistrationService {

  constructor(private networkService: NetworkService) { }

  public cancel(id: number, accessTokenId?: string): Observable<ISuccessResponse> {
    let path = `Registrations/${id}/cancel`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.PUT, path);
  }

  public getById(id: number, includeExternalRegistration: boolean = false, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.GET, path, { includeExternalRegistration });
  }

  public getNda(id: number, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/nda/${id}`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest<RegistrationExpanded>(HTTPMethod.GET, path);
  }

  public updateGuests(id: number, registration: RegistrationExpanded,
    accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest<RegistrationExpanded>(HTTPMethod.PUT, path, { data: registration });
  }

  public getForScreening(screeningId: number): Observable<RegistrationExpanded> {
    return this.networkService.httpRequest(HTTPMethod.GET, `Registrations/getForScreening`, { screeningId });
  }

  public addToWaitlist(id: number): Observable<RegistrationExpanded> {
    return this.networkService.httpRequest(HTTPMethod.PUT, `Registrations/${id}/waitlist`);
  }

  public changeScreening(id: number, newScreeningId: number, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/changeScreening`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.POST, path, { newScreeningId });
  }

  public getByIdCheckIn(id: string, screeningId: number, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/pre-check`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.GET, path, { screeningId });
  }

  public sendNDA(id: string, screeningId: number, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/sendNDA`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.PUT, path, { screeningId });
  }

  public verifyNDA(id: number, token: string, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/verify`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.POST, path, { token });
  }

  public getByIdNda(id: string, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/nda`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.GET, path);
  }

  public getSignedUploadRequest(file: any, name: string, accessTokenId?: string): Observable<any> {
    let path = `Media/upload?fileType=${file.type}&fileName=${name}`;
    if (accessTokenId) {
      path += `&access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.GET, path);
  }

  public uploadFile(url: string, file: any): Observable<any> {
    return this.networkService.uploadFile(url, file);
  }

  public ndaUpdate(registrationId: number, signature: string, customFields: any, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/ndaUpdate`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.POST, path, { registrationId, signature, customFields });
  }

  public getNDAPDF(id: number, accessTokenId?: string): Observable<RegistrationExpanded> {
    let path = `Registrations/${id}/getNDAPDF`;
    if (accessTokenId) {
      path += `?access_token=${accessTokenId}`;
    }
    return this.networkService.httpRequest(HTTPMethod.GET, path);
  }
}

export { RegistrationService };
