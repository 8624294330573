import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { AccountExpanded, CALENDAR_MONTH_OPTIONS, Kid, MinorExpanded, QuestionTemplate, RegistrationData } from '../../../../../../common/interfaces';
import { AccountService, CountryService, QuestionTemplateService } from '../../../../services';
import { FieldValidator } from '../../../common/validator';
import { KidForm } from '../../forms';
import { QuestionTemplateForm } from '../question-template-form';

@Component({
  providers: [CountryService],
  selector: 'registration-form',
  styleUrls: ['./registration-form.component.scss'],
  templateUrl: './registration-form.component.pug'
})
export class RegistrationForm implements OnInit {
  @Input() public account: AccountExpanded;
  @Input() public isScreeningRegistration: boolean;
  @Input() public onlineFocusGroup: boolean;
  @Input() public updateFilter: boolean;
  @Input() public filterType: number = 0;
  @Input() public disabled: boolean;
  @Input() public showHighlightedError: boolean;
  @Output() public validationStateChanged = new EventEmitter<boolean>();
  @ViewChild(QuestionTemplateForm, { static: false }) public templateForm: QuestionTemplateForm;
  @ViewChildren(KidForm) public minorForms: QueryList<KidForm>;

  public parentOptions: Object[] = [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ];

  public subscribeOptions: Object[] = [
    {
      label: 'Yes',
      value: true
    },
    {
      label: 'No',
      value: false
    }
  ];

  public preferredContactOptions: String[] = [
    'Email',
    'SMS/Text Message'
  ]

  public form: FormGroup;
  public calendarMonthOptions: Array<any> = CALENDAR_MONTH_OPTIONS;
  public countryOptions: Array<any>;
  public country: string;
  public countryId: number;
  public birthMonth: string;
  public birthDay: any;
  public birthYear: any;
  public birthdateError: string;
  private isDateValid = false;
  private isValid = false;
  private postalRegex: string = '^\\b\\d{5}\\b(?:[- ]{1}\\d{4})?$';
  private countryList: Array<any>;
  public children = Array<Kid>();
  public isChildValid: boolean;
  public childCount: number;
  public childrenOptions: Array<number>;
  public isGuestValid: boolean;
  public loaded: boolean = false;
  public isEditing: boolean = false;
  public isMobile: boolean = false;
  public templates: Array<QuestionTemplate> = [];
  public childTemplates: Array<QuestionTemplate> = [];

  constructor(private route: ActivatedRoute, public formBuilder: FormBuilder, private accountService: AccountService,
    private countryService: CountryService, private questionTemplateService: QuestionTemplateService, private cdr: ChangeDetectorRef) { }

  public ngOnInit() {
    this.isEditing = this.route.snapshot.data['isEditing'];
    this.countryService.get().subscribe(
      (countries: any) => {
        const index = countries.findIndex((country) => country.id === 241);
        const ukCountry = [countries[index]];
        const firstCountry = [countries[0]]
        const leftCountries = countries.slice(1, index);
        const rightCountries = countries.slice(index + 1, countries.length);

        const sortedCountries = firstCountry.concat(ukCountry).concat(leftCountries).concat(rightCountries)
        this.countryList = sortedCountries;

        this.countryOptions = sortedCountries.map(x => x.country);
        this.accountService.currentUser.subscribe(
          currentUser => {
            // if (!currentUser || !currentUser.emailVerified) {
            //   return;
            // }
            this.questionTemplateService.get(this.filterType).subscribe(
              templates => {
                this.templates = templates.filter(qt => !qt.isForMinor)
                this.childTemplates = templates.filter(qt => qt.isForMinor)
              },
              err => console.log('QuestionTemplate error - ', err)
            );
            this.account = this.route.snapshot.data['isEditing'] && !currentUser ? this.account : currentUser;
            this.form = this.getUpdatedFormGroup();
            this.form.valueChanges.subscribe(data => this.checkValidationState());
            this.loaded = true;
            this.checkValidationState();
          }
        );
      },
      (err: any) => { console.log('Error retrieving countries ', err); }
    );
    this.childrenOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    // this.checkValidationState();
  }

  public getUpdatedFormGroup() {
    if (!this.account) {
      this.account = {};
    }
    if (this.account.birthDate) {
      let birthdate = typeof this.account.birthDate === "string" ? this.account.birthDate : this.account.birthDate.toISOString()
      let birthday = birthdate.split('T')[0].split('-');
      if (birthday.length == 3 && !birthday.some((ele) => isNaN(Number(ele)))) {
        this.birthMonth = CALENDAR_MONTH_OPTIONS[Number(birthday[1]) - 1];
        this.birthDay = Number(birthday[2]);
        this.birthYear = Number(birthday[0]);
      }
    }
    if (this.account.Country) {
      this.countryId = this.account.countryId;
      this.country = this.account.Country.country;
    }
    if (this.account.isParent) {
      this.childCount = this.account.Minors?.length || 1 //must have 1 child if parent
      this.onChildCountChanged(this.childCount, this.account.Minors)
    }
    return this.newGroup();
  }

  public newGroup() {
    const formGroup = this.formBuilder.group({
      firstName: [this.account.firstName, FieldValidator.validateName],
      lastName: [this.account.lastName, FieldValidator.validateName],
      email: [this.account.email, FieldValidator.emailFormat],
      phoneNumber: [this.account.phoneNumber, FieldValidator.validatePhoneNumber],
      birthMonth: this.birthMonth,
      birthDay: this.birthDay,
      birthYear: this.birthYear,
      birthDate: this.account.birthDate,
      phoneType: this.account.phoneType,
      smsEnabled: this.account.smsEnabled,
      countryId: this.countryId,
      country: this.country,
      postalCode: [this.account.postalCode, FieldValidator.validateZipCode(this.postalRegex, this.countryId)],
      isParent: this.account.isParent,
      language: this.account.primaryLanguage,
      unsubscribe: this.account.unsubscribe,
      preferredContactInvite: this.account.preferredContactInvite,
      preferredContactReminder: this.account.preferredContactReminder
    });
    formGroup.controls.isParent.valueChanges.subscribe(data => this.cdr.detectChanges())
    formGroup.controls.country.valueChanges.subscribe(data => this.countryChanged(data))
    formGroup.controls.phoneType.valueChanges.subscribe(data => this.phoneTypeChanged(data))
    formGroup.controls.isParent.valueChanges.subscribe(data => this.isParentChanged(data))
    return formGroup;
  }

  public values(): RegistrationData {
    let formValues = this.form.value;
    if (this.isDateValid) {
      let date = new Date(formValues.birthMonth + ' ' + formValues.birthDay + ', ' + formValues.birthYear);
      let birthdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()));
      this.account.birthDate = birthdate;
    }
    this.account.countryId = this.countryId;

    this.account.firstName = formValues.firstName;
    this.account.lastName = formValues.lastName;
    this.account.email = formValues.email;
    this.account.phoneNumber = formValues.phoneNumber;
    this.account.phoneType = formValues.phoneType;
    this.account.smsEnabled = formValues.smsEnabled;
    this.account.postalCode = formValues.postalCode;
    this.account.isParent = formValues.isParent?.value ?? formValues.isParent;
    this.account.primaryLanguage = formValues.primaryLanguage;
    //this.account.unsubscribe = formValues.unsubscribe;
    this.account.preferredContactInvite = formValues.preferredContactInvite;
    this.account.preferredContactReminder = formValues.preferredContactReminder;

    let data: RegistrationData = {
      Account: this.account,
      Answers: [],
      Children: []
    };
    if (!this.isScreeningRegistration && this.templateForm) {
      let templateValues = this.templateForm.values();
      if (templateValues) {
        data.Answers = templateValues.Answers;
        data.isIndustryValid = templateValues.isIndustryValid;
      }
    }
    if (this.account.isParent) {
      data.Children = this.minorForms.map(m => m.values());
    }
    return data;
  }

  public birthdateValidation() {
    this.isDateValid = false;
    let year = this.form.value.birthYear;
    let isValidYear = year > 1900 && year <= new Date().getFullYear();
    if (year && year.toString().length < 4 || !isValidYear) {
      if (year) {
        this.birthdateError = 'Invalid year';
      }
      return;
    }
    let month = new Date(Date.parse(this.form.value.birthMonth + ' 1, 2016')).getMonth() + 1;
    let day = this.form.value.birthDay;
    if (!moment(year + '-' + month + '-' + day, 'YYYY-M-DD').isValid()) {
      this.birthdateError = 'Invalid date';
      return;
    }
    if (!month || !day) {
      this.birthdateError = 'Required';
      return;
    }
    this.birthdateError = '';
    this.isDateValid = true;
  }

  public valid() {
    // always valid if form is disabled
    if (this.disabled || this.onlineFocusGroup) {
      return true;
    }
    const minorValid = (this.minorForms ? this.minorForms.reduce((p, c) => (p && c.valid()), true) : true)
    return this.isValid && minorValid && (this.templateForm ? this.templateForm.valid() : true);
  }

  public getCountryName(id: number) {
    if (id != null && (id < this.countryList.length && id > -1)) {
      this.countryId = this.countryList[id].country;
    } else {
      return '';
    }
  }

  public countryChanged(event: string) {
    for (let c in this.countryList) {
      if (this.countryList[c].country === event) {
        this.postalRegex = this.countryList[c].regex;
        this.countryId = this.countryList[c].id;
        this.country = this.countryList[c].country;
        this.form.controls.postalCode.setValidators(FieldValidator.validateZipCode(this.postalRegex, this.countryId));
        this.form.controls.postalCode.updateValueAndValidity();
      }
    }
  }

  public onChildCountChanged(childCount: number, childData: MinorExpanded[] = []) {
    if (childCount < 1) {
      this.isChildValid = true;
    }
    if (isNaN(childCount)) {
      return;
    }
    this.children = childData.filter((x, i) => i < childCount);;
    if (this.children.length < childCount) {
      this.children.push(...(new Array(childCount - this.children.length).fill({})))
    }
    this.cdr.detectChanges();
  }

  // this will emit any time state changes
  private checkValidationState() {
    this.birthdateValidation();
    if ((this.form.valid && this.isDateValid) !== this.isValid) {
      this.isValid = this.form.valid && this.isDateValid;
      this.validationStateChanged.emit(this.isValid);
    }
  }

  private phoneTypeChanged(phoneType) {
    this.isMobile = (phoneType === "Mobile")
  }

  private isParentChanged(isParent) {
    this.account.isParent = !!(typeof isParent?.value === 'boolean' ? isParent.value : isParent)
    if (this.account.isParent) {
      this.childCount = this.account.Minors?.length || 1 //must have 1 child if parent
      this.onChildCountChanged(this.childCount, this.account.Minors)
    } else {
      this.childCount = 0
      this.onChildCountChanged(this.childCount, this.account.Minors)
    }
  }
}
