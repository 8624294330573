import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { CookieService } from 'ngx-cookie-service';

import { AccountService } from '../../../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'email-confirmation-page',
  styleUrls: ['./email-confirmation-page.component.scss'],
  templateUrl: './email-confirmation-page.component.pug'
})
export class EmailConfirmationPage implements OnInit {
  public email: string;
  public showSuccessAlert?: boolean;
  public showErrorAlert?: boolean;
  public verifyCodeForm: FormGroup;
  public logId: number;
  private firstEl: HTMLInputElement;
  public submitted: boolean = false;
  public showError: any = null;
  public success: any = null;
  public waiting: boolean = false;
  private accessTokenId: string;

  constructor(private titleService: Title, private accountService: AccountService, private route: ActivatedRoute, private router: Router, private cookieService: CookieService) {}

  public ngOnInit() {
    // let userEmail = this.cookieService.get('email');
    let userEmail = this.route.snapshot.queryParams['email']
    this.email = userEmail ? userEmail : '';
    this.logId = this.route.snapshot.queryParams['logId']
    this.titleService.setTitle('Confirm Email');

    const TOKEN_KEY = 'access_token';
    let accessTokenId = this.route.snapshot.queryParams[TOKEN_KEY];
    if (accessTokenId) {
      this.accessTokenId = accessTokenId;
    } else {
      this.accessTokenId = this.cookieService.get('accessToken');
    }
    this.verifyCodeForm = new FormGroup(Array.from(Array(6)).reduce((p, c, i) => (p[`${i}`] = new FormControl(null, Validators.required), p), {}))

  }

  public ngAfterViewChecked() {
    if (!(!!this.firstEl)) {
      const els = Array.from(document.getElementsByClassName('verify'))
      const subEl = document.getElementById('submit')
      els.forEach((el, i) => {
        if (!i) {
          el.addEventListener("paste", (event: ClipboardEvent) => {
            event.preventDefault();
            const paste = event.clipboardData.getData("text");
            this.verifyCodeForm.setValue(paste.substring(0,6).split('').reduce((p, c, ix) => (p[ix] = c.replace(/[^0-9]/g, ''), p), {}))
          });
        }
        el.addEventListener("keypress", (event: KeyboardEvent) => {
          if (!(/^[0-9]$/.test(event.key) || event.key === "Backspace" || event.key === "Delete")) {
            event.preventDefault();
          }
        })
        el.addEventListener("keyup", (event: KeyboardEvent) => {
          const target = event.target as HTMLInputElement
          if (event.key === 'v' && event.ctrlKey) {
            if (subEl) subEl.focus()
          } else if (!!target.value && /^[0-9]$/.test(event.key)) {
            const next = target.nextElementSibling as HTMLInputElement
            if (next) {
              next.focus()
              next.select()
            } else {
              if (subEl) subEl.focus()
            }
          }
        });
      })
      this.firstEl = els[0] as HTMLInputElement
      this.firstEl.focus()
    }
  }

  public submit(values: any) {
    this.submitted = true;
    this.success = null;
    this.showError = null;
    const code = Object.keys(values).reduce((p, c) => (p[c] = values[c], p), []).join('')
    this.accountService.verifyCode(this.email, code, this.accessTokenId).subscribe(
      (resp: any) => {
        this.submitted = false;
        if (resp) {
          this.success = 'Your Account has been verified.'
          if (this.logId) {
            this.accountService.updateRecruiterLog(this.logId, { status: "Valid" }).subscribe(
              () => {
                this.router.navigate(["verification"]);
              },
              (error) => {
                this.showError = 'An error has occurred. Please try again.'
                this.submitted = false;
              }
            );
          } else {
            this.router.navigate(["verification"]);
          }
        } else {
          this.showError = 'An error has occurred. Please try again.'
          this.submitted = false;
        }
      },
      (err) => {
        this.showError = 'An error has occurred. Please try again.'
        this.submitted = false;
        this.waiting = false;
        console.log('Error', err)
      }
    );
  }

  public resend() {
    this.waiting = true;
    this.success = null;
    this.showError = null;
    this.verifyCodeForm.reset();
    if (this.firstEl) this.firstEl.focus()
    this.accountService.resendCode(this.email).subscribe((resp) => {
      if (resp && resp.message) {
        this.success = 'Your Verification email has been resent'
        this.waiting = false;
      } else {
        this.showError = 'An error has occurred. Please try again.'
        this.waiting = false;
      }
    },
      (err) => {
        this.showError = 'An error has occurred. Please try again.'
        this.waiting = false;
        console.log('Error', err)
      });
  }

  public resendEmailButtonPressed(email: string) {
    this.showErrorAlert = false;
    this.showSuccessAlert = false;
    this.accountService.getSelf().subscribe(
      account => {
        this.accountService.resendVerificationEmail(email).subscribe(
          updated => {
            this.showSuccessAlert = true;
          },
          err => {
            this.showErrorAlert = true;
          }
        );
      },
      err => {
        this.showErrorAlert = true;
      }
    );
  }
}
