import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Answer, CALENDAR_MONTH_OPTIONS, Ethnicity, MinorExpanded, QuestionDataType, QuestionTemplate, QuestionTemplateCode } from '../../../../../../common/interfaces';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'kid-form',
  styleUrls: ['./kid-form.component.scss'],
  templateUrl: './kid-form.component.pug'
})
export class KidForm implements OnInit, OnChanges {
  @Input() public childNumber: number;
  @Input() public child: MinorExpanded = {};
  @Input() public questionTemplates: Array<QuestionTemplate> = [];
  @Input() public isEditing = false;
  @Input() public showHighlightedError: boolean;
  @Output() public childValidationStateChanged = new EventEmitter<boolean>();

  public form: FormGroup;
  public childs: Array<any>;
  public options: Array<any>;
  public firstNameControl = new FormControl('', FieldValidator.validateName);
  public lastNameControl = new FormControl('', FieldValidator.validateName);
  public childQuestions: Array<QuestionTemplate> = [];
  public childAnswers: any = {};
  private isValid: boolean;
  public childLabel: string;
  // public formControlSuffix: string;
  public calendarMonthOptions: Array<any> = CALENDAR_MONTH_OPTIONS;
  public birthdateError: string;
  private isDateValid = false;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit() {
    this.childLabel = `Child ${this.childNumber} `;
  }

  public ngOnChanges() {
    if (this.child && Object.keys(this.child).length) {
      if (Array.isArray(this.child.Answers)) {
        this.child.Answers.forEach((answer) => {
          if (answer.answerJSON && answer.answerJSON.values[0]) {
            let value = answer.answerJSON.values
            let mappedValues = answer.answerJSON.mappedValues
            this.childAnswers[answer.questionTemplateId] = {
              id: answer.id,
              value: Array.isArray(value[0]) ? value.filter(x => x[1]).map(x => x[0]) : value[0],
              values: value,
              mappedValues
            }
          }
        })
      }
    }
    this.childQuestions = this.questionTemplates.map(x => structuredClone(x));
    this.setUpForm();
  }

  public valid() {
    return this.isValid;
  }

  public values() {
    let child: MinorExpanded = this.child;
    child.Answers = child.Answers ? child.Answers : [];
    child.birthMonth = CALENDAR_MONTH_OPTIONS.findIndex(x => x === this.form.controls['birthMonth']?.value) + 1;
    child.birthYear = this.form.controls['birthYear']?.value;
    if (this.childQuestions) {
      this.childQuestions.forEach(question => {
        let control = this.form.controls[question.formControlName];
        let existing = this.childAnswers[question.id];
        if (control.value || existing) {
          // get answer data
          let existingIdx = child.Answers.findIndex(ans => existing && ans.id === existing.id)
          let values = control.value ? [] : existing.values || [];
          let checkgroup = question.configJSON && question.configJSON.dataType === QuestionDataType.CheckGroup;
          if (checkgroup) {
            // make an array of selected values
            let obj = Object.keys(control.value).reduce((p, c) => {
              if (control.value[c] === true) p[c] = true;
              return p;
            }, {});
            values = Object.entries(obj);
          } else {
            values.push(control.value);
          }
          if (existingIdx !== -1) {
            let answer: Answer = {
              ...child.Answers[existingIdx],
              // questionId: question.id,
              questionTemplateId: question.id,
              answerJSON: { values, label: question.label }
            }
            child.Answers[existingIdx] = answer
          } else {
            let answer: Answer = {
              // questionId: question.id,
              questionTemplateId: question.id,
              answerJSON: { values, label: question.label }
            };
            child.Answers.push(answer);
          }

          // update gender/ethnicity/age properties
          if (question.fieldCode === QuestionTemplateCode.ChildEthnicity) {
            child.ethnicities = checkgroup ? Object.entries(control.value).filter(([x, y]) => !!x && !!y).map((([x]) => x)) as Ethnicity[] : [control.value]
            child.ethnicity = checkgroup ? child.ethnicities.length > 1 ? 'Multi-Ethnic' : child.ethnicities[0] : control.value;
          } else if (question.fieldCode === QuestionTemplateCode.ChildGender) {
            child.gender = control.value;
          }
          // else if (question.fieldCode === QuestionTemplateCode.ChildAge) {
          //   child.ageGroup = control.value;
          // }
        }
      });
    }
    return child;
  }

  private setUpForm() {
    let group: any = {
      birthMonth: this.child.birthMonth && CALENDAR_MONTH_OPTIONS[this.child.birthMonth - 1],
      birthYear: this.child.birthYear,
    };
    this.childQuestions.forEach(question => {
      question.formControlName = `${question.id}${this.childNumber}`;
      let control = this.form?.controls[question.formControlName];
      // set empty answer for freeform text questions to prevent 'undefined' in textarea
      let q: any = question;
      if (q.configJSON.dataType === 1) {
        q.answer = this.childAnswers[q.id]?.value || control?.value || q.answer || '';
      } else if (this.childAnswers[q.id]) {
        if (Array.isArray(this.childAnswers[q.id].value) && q.configJSON.dataType === 408) {
          q.answer = Object.fromEntries(this.childAnswers[q.id].values)
        } else {
          q.answer = this.childAnswers[q.id].value || null
        }
      }

      if (q.isRegistrationOptional) {
        group[question.formControlName] = new FormControl(q.answer || null);
      } else {
        const validator = q.configJSON.dataType === QuestionDataType.CheckGroup ? FieldValidator.requiredCheckgroup : q.configJSON.dataType === QuestionDataType.Grid ? FieldValidator.requiredGridQuestion : Validators.required
        group[question.formControlName] = new FormControl(q.answer || null, validator);
      }
    });

    this.form = this.formBuilder.group(group);
    this.checkValidationState();
    this.form.valueChanges.subscribe(data => this.checkValidationState());
  }

  public birthdateValidation() {
    this.isDateValid = false;
    const today = new Date();
    let month = new Date(Date.parse(this.form.controls['birthMonth']?.value + ' 1, 2016')).getMonth() + 1;
    let year = this.form.controls['birthYear']?.value;
    if (!month && year) {
      this.birthdateError = 'Required';
      return;
    }
    let age = today.getFullYear() - year
    if (today.getMonth() < (month - 1)) age--
    if (year && year.toString().length < 4) {
      this.birthdateError = 'Invalid year';
      return;
    } else if (!year || (age < 7 || age > 17)) {
      if (year) {
        this.birthdateError = 'Age must be between 7 and 17';
      }
      return;
    }
    this.birthdateError = '';
    this.isDateValid = true;
  }

  private checkValidationState() {
    this.birthdateValidation();
    if ((this.form.valid && this.isDateValid) !== this.isValid) {
      this.isValid = this.form.valid && this.isDateValid;
      this.childValidationStateChanged.emit(this.isValid);
    }
  }

}
