import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'question',
  styleUrls: ['./question.component.scss'],
  templateUrl: './question.component.pug'
})
export class Question {
  @Input() public question: any;
  @Input() public form: FormGroup;
  @Input() public customDisplay: string;
  @Input() public guestNumber: number;
  @Input() public guestType: string = '';
  @Input() public showHighlightedError: boolean;
  @Output() public answerChanged = new EventEmitter<any>();
  @Output() public guestAnswerChanged = new EventEmitter<any>();
  public options: string[] = [];
  public displayText: string[] = [];
  public exclusiveIndex: number;
  public formControlError: string;
  public answerValid: boolean = false;

  private shuffleArray(original: any[], display: any[], end?) {
    const { array, sticky, text, stickyText } = original.reduce((p, c, i) => {
      if (parseInt(end) === i) {
        p.sticky.push(c)
        p.stickyText.push(display[i])
      } else {
        p.array.push(c)
        p.text.push(display[i])
      }
      return p
    }, { array: [], sticky: [], text: [], stickyText: [] })
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      const temp2 = text[i];
      array[i] = array[j];
      array[j] = temp;
      text[i] = text[j];
      text[j] = temp2;
    }
    return {
      options: [...array, ...sticky],
      text: [...text, ...stickyText]
    }
  }

  public onAnswerChanged($answer) {
    if (this.question.configJSON.dataType !== 808) {
      this.answerValid = typeof $answer === 'object' ? Array.isArray($answer) ? !!$answer.length : $answer ? Object.values($answer).includes(true) : false : !!$answer;
    } else if (this.question.configJSON.dataType === 808) {
      if (typeof $answer === 'object') {
        const allValuesDefined = Object.values($answer).every(value => value !== undefined);
        if (allValuesDefined) {
          this.answerValid = true
        } else {
          this.answerValid = false
        }
      } else {
        this.answerValid = !!$answer
      }
    }
    let dataType = this.question && this.question.configJSON ? this.question.configJSON.dataType : null;
    // emit if the answer to this question affects other questions
    //  if (this.question.configJSON.shouldEmit) {
    let event = {
      questionId: this.question.id,
      answer: $answer,
      dataType
    };
    this.answerChanged.emit(event);
    //  }
  }

  public ngOnInit() {
    if (this.question) {
      const answers = this.question.configJSON.dataType === 808 ? this.question.configJSON.options : this.question.configJSON.answers
      const displayText = !this.question.configJSON.mapped ? this.question.configJSON.dataType === 808 ? this.question.configJSON.optionText : this.question.configJSON.answerText : []
      const { options, text } = this.question.configJSON.randomize ? this.shuffleArray(answers, displayText || [], this.question.configJSON.exclusiveIndex) : { options: answers, text: displayText || [] };
      if (this.question.configJSON.randomize && this.question.configJSON.anchorValues && Array.isArray(this.question.configJSON.anchorValues) && this.question.configJSON.anchorValues.length) {
        const { start, end } = options.reduce((p, c, i) => {
          if (!this.question.configJSON.anchorValues.includes(c)) {
            p.start.options.push(c)
            p.start.text.push(text[i])
          } else {
            p.end.options.push(c)
            p.end.text.push(text[i])
          }
          return p
        }, { start: { options: [], text: [] }, end: { options: [], text: [] } })
        this.options = [...start.options, ...end.options]
        this.displayText = [...start.text, ...end.text]
      } else {
        this.options = options
        this.displayText = text
      }

      this.exclusiveIndex = ![null, undefined].includes(this.question.configJSON.exclusiveIndex) ? (this.question.configJSON.randomize ? this.options.length - 1 : parseInt(this.question.configJSON.exclusiveIndex)) : null
      if (this.question.isRequired && !this.question.hidden) {
        if (this.question.configJSON.dataType === 408) {
          this.form.controls[this.question.formControlName].setValidators(FieldValidator.requiredCheckgroup)
        } else if (this.question.configJSON.dataType === 358) {
          this.formControlError = `{'invisible': form.controls.${this.question.formControlName}.value && !form.controls.${this.question.formControlName}.dirty}`;
        } else if (this.question.configJSON.dataType === 808) {
          this.form.controls[this.question.formControlName].setValidators(FieldValidator.requiredGridQuestion)
        }
      }
      if (this.question.answer) this.answerValid = typeof this.question.answer === 'object' ? Array.isArray(this.question.answer) ? !!this.question.answer.length : Object.values(this.question.answer).includes(true) : !!this.question.answer;
      this.form.controls[this.question.formControlName].valueChanges.subscribe(data => this.onAnswerChanged(data))
    }
  }
}
