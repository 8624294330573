import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'checkbox-input',
  styleUrls: ['./checkbox-input.component.scss'],
  templateUrl: './checkbox-input.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxInput),
    multi: true
  }]
})
export class CheckboxInput implements ControlValueAccessor {
  @Output() public valueChanged = new EventEmitter<any>();
  @Input() public label: string;
  @Input() public displayText: string;
  @Input() public questionId: any;
  @Input() public guestNumber: any;
  public value: boolean;
  public answerKey: string;

  public ngOnInit() {
    this.questionId = this.questionId || Math.floor(Math.random() * 1000) + 1;
    this.questionId = String(this.questionId);
    this.answerKey = this.questionId + this.label;
    if (this.guestNumber) { this.answerKey += this.guestNumber; }
  }

  public writeValue(value: any) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  public propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public onClick() {
    // emit value as !value because of css
    let event = {
      value: !this.value,
      label: this.label
    };
    this.valueChanged.emit(event);
    this.propagateChange(!this.value);
  }

  public registerOnTouched() { }
}
