import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'dropdown-picker',
  templateUrl: './dropdown-picker.component.pug',
  styleUrls: ['./dropdown-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownPicker),
    multi: true
  }]
})
export class DropdownPicker implements ControlValueAccessor, OnInit {
  @Output() public onValueChanged: EventEmitter<any>;
  @Input() public options: Array<any> = [];
  @Input() public optionText: string[] = [];
  @Input() public labelText: string;
  @Input() public value: any;
  @Input() public isInvalid: any;
  @Input() public selectedOption: any;
  @ViewChild('dropdownPicker', { static: false }) dropdownPicker: BsDropdownDirective;
  public opened = false;
  public fullOptions: Array<any> = []
  public filteredOptions: Array<any> = []
  public isSerached = false;
  public searchText: string = '';

  ngOnInit() {
    this.fullOptions = this.options.map((option, i) => {
      return {
        value: option?.value ?? option,
        label: (option?.label || option) + (this.optionText[i] ? ` ${this.optionText[i]}` : "")
      }
    })
    this.filteredOptions = this.fullOptions;
  }

  toggle(event: Event) {
    event.stopPropagation(); // Prevent click event from propagating
    event.preventDefault(); // Prevent default behavior
    this.dropdownPicker.show();
    this.scrollToItem();
  }

  public propagateChange = (_: any) => { };

  public onOptionSelected(option: any, resetFilter: boolean = false) {
    this.selectedOption = option;
    this.value = option?.value ?? option;
    this.searchText = option?.value ?? option;

    if (resetFilter) {
      // Reset filteredOptions back to the original options
      this.filteredOptions = [...this.options];

      // Set selectedIndex to the position of selectedOption in options
      this.selectedIndex = this.options.findIndex(opt =>
        opt === option || opt === option.value || (opt.value != null && (opt.value === option.value || opt.value === option))
      );
    }

    this.propagateChange(this.value);
  }

  public writeValue(value: any) {
    if (value !== this.labelText && value !== this.value) {
      this.value = value;
      this.selectedOption = null;
      if (this.options) {
        for (let [index, option] of this.options.entries()) {
          if (option === value || (option?.value === value)) {
            this.selectedOption = option;
            this.selectedIndex = index;
            this.searchText = option?.value ?? option;

            break;
          }
        }
      }
    }
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched() { }

  public onSearch(event: any) {
    if (event.target.value && event.target.value != "") {
      this.filteredOptions = this.fullOptions.filter(function (option) {
        return ((option?.value ?? option).toString().toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) ? true : false;
      }).sort((a, b) => {
        const aValue = (a?.value ?? a).toString().toLowerCase();
        const bValue = (b?.value ?? b).toString().toLowerCase();
        const inputValue = event.target.value.toLowerCase();

        if (aValue.startsWith(inputValue) && bValue.startsWith(inputValue)) return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });
        else if (aValue.startsWith(inputValue)) return -1;
        else if (bValue.startsWith(inputValue)) return 1;

        return aValue.localeCompare(bValue, 'en', { sensitivity: 'base' });;
      });
      this.isSerached = true

      // Auto-select the first filtered item if any exist
      if (this.filteredOptions.length > 0) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex = -1;
        this.selectedOption = null;
        this.value = null;
        this.propagateChange(this.value);
      }
    } else {
      this.filteredOptions = this.fullOptions
      this.isSerached = false;
      this.selectedIndex = -1;
    }
    this.toggle(event);
    this.scrollToItem();
  }

  selectedIndex: number = -1;

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (!this.filteredOptions || this.filteredOptions.length === 0) {
      this.selectedOption = null;
      this.value = null;
      this.dropdownPicker.hide();
      this.propagateChange(this.value);
    }

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectedIndex < this.filteredOptions.length - 1) {
          this.selectedIndex++;
        } else {
          this.selectedIndex = 0; // Loop back to the start
        }
        if (this.filteredOptions[this.selectedIndex] !== undefined)
          this.onOptionSelected(this.filteredOptions[this.selectedIndex]);
        else
          this.onOptionSelected(this.options[0], true);
        this.scrollToItem();
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        } else {
          this.selectedIndex = this.filteredOptions.length - 1; // Loop to the end
        }
        if (this.filteredOptions[this.selectedIndex] !== undefined)
          this.onOptionSelected(this.filteredOptions[this.selectedIndex]);
        else
          this.onOptionSelected(this.options[this.options.length - 1], true);
        this.scrollToItem();
        break;
      case 'Enter':
        if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredOptions.length) {
          this.onOptionSelected(this.filteredOptions[this.selectedIndex], true);
          event.preventDefault();
          this.dropdownPicker.hide();
        }
        break;
      case 'Tab':
        if (this.selectedIndex >= 0 && this.selectedIndex < this.filteredOptions.length) {
          this.onOptionSelected(this.filteredOptions[this.selectedIndex], true);
          this.dropdownPicker.hide();
        }

        break;
    }
  }

  scrollToItem() {
    const items = document.querySelectorAll('.dropdown-item');
    if (items[this.selectedIndex]) {
      items[this.selectedIndex].scrollIntoView({ block: 'nearest' });
    }
  }

}
