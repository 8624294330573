import { Component, ElementRef, EventEmitter, Input, Optional, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'radio-input',
  styleUrls: ['./radio-input.component.scss'],
  templateUrl: './radio-input.component.pug',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioInput),
    multi: true
  }]
})
export class RadioInput implements ControlValueAccessor {
  @Optional() public fieldset: HTMLFieldSetElement
  @Output() public valueChanged = new EventEmitter<any>();
  @Input() public label: string;
  @Input() public answer: string;
  @Input() public option: string;
  @Input() public questionId: any;
  @Input() public guestNumber: any;
  @Input() public selected: any;
  public answerKey: string;
  private disabled: boolean = false;

  constructor(private elRef: ElementRef) { }

  public ngOnInit() {
    this.disabled = !!this.elRef.nativeElement.closest("fieldset")?.disabled
    this.questionId = this.questionId || Math.floor(Math.random() * 1000) + 1;
    this.questionId = String(this.questionId);
    this.answerKey = this.questionId;
    if (this.label) { this.answerKey += this.label; }
    if (this.answer && this.option) { this.answerKey += this.answer + this.option; }
    if (this.guestNumber) { this.answerKey += this.guestNumber; }
  }

  public writeValue(selected: any) {
    if (selected !== this.selected) {
      this.selected = selected;
    }
  }

  public propagateChange = (_: any) => { };

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public onClick() {
    if (!this.disabled) {
      // emit value as !value because of css
      let event = {
        option: this.option,
        answer: this.answer
      };
      //this.selected = this.answer;
      this.valueChanged.emit(event);
      this.propagateChange(!this.selected);
    }
  }

  public registerOnTouched() { }
}
