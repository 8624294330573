import {
  ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
  AccountExpanded, Answer, Ethnicity, Question, QuestionDataType, QuestionTemplate,
  QuestionTemplateCode, QuestionTemplateRegistrationFilter,
  RegistrationData
} from '../../../../../../common/interfaces';
import { IndustryForm } from '../../forms';
import { FieldValidator } from '../../../common/validator';

@Component({
  selector: 'question-template-form',
  styleUrls: ['./question-template-form.component.scss'],
  templateUrl: './question-template-form.component.pug'
})
export class QuestionTemplateForm implements OnChanges {
  @Input() public account: AccountExpanded;
  @Input() public filterType: number;
  @Input() public questionTemplates: Array<QuestionTemplate>;
  @Output() public validationStateChanged = new EventEmitter<boolean>();
  @ViewChild(IndustryForm, { static: false }) public industryForm: IndustryForm;

  public industryQuestion: Question;
  public form: FormGroup;
  public templates: Array<QuestionTemplate>;

  constructor(public formBuilder: FormBuilder) { }

  public ngOnChanges() {
    this.form = this.formBuilder.group({});
    this.updateQuestionTemplates(this.questionTemplates)
  }

  public values() {
    if (!this.account) {
      this.account = {};
    }

    let data: RegistrationData = {
      Answers: [],
      isIndustryValid: !this.industryForm || this.industryForm.valid()
    };
    this.templates.forEach(template => {
      let control = this.form.controls[template.id];

      if (!control.pristine) {
        let values = [];
        let checkgroup = template.configJSON && template.configJSON.dataType === QuestionDataType.CheckGroup;
        if (checkgroup) {
          // checkgroup control values are like { key: value }, but we need to store them as [[key, value]];
          let obj = Object.keys(control.value).reduce((p, c) => {
            if (control.value[c] === true) p[c] = true;
            return p;
          }, {});
          values = Object.entries(obj);
        } else {
          values.push(control.value);
        }
        // get answer data
        let answer: Answer = {
          questionTemplateId: template.id,
          accountId: this.account.id,
          answerJSON: { values, label: template.label }
        };
        data.Answers.push(answer);

        // update gender/ethnicity properties
        if (template.fieldCode === QuestionTemplateCode.Ethnicity) {
          this.account.ethnicities = checkgroup ? Object.entries(control.value).filter(([, y]) => !!y).map((([x]) => x)) as Ethnicity[] : [control.value]
          this.account.ethnicity = checkgroup ? this.account.ethnicities.length > 1 ? 'Multi-Ethnic' : this.account.ethnicities[0] : control.value;
        } else if (template.fieldCode === QuestionTemplateCode.Gender) {
          this.account.gender = control.value;
        }
      }
    });

    data.Account = this.account;
    return data;
  }

  private updateQuestionTemplates(templates: Array<QuestionTemplate>) {
    templates = templates.filter((template: any) => {

      // show fields of employment as a separate component in registrations. Omit from 'My account' page.
      if (template.fieldCode === QuestionTemplateCode.FieldsOfEmployment) {
        if (this.filterType !== QuestionTemplateRegistrationFilter.None) {
          this.industryQuestion = template;
        }
        return false;
      }

      let config: any = template.configJSON;
      let hasAnswers = config && config.answers && config.answers.length > 0;
      let isValid = hasAnswers || (config && config.dataType === QuestionDataType.Text);
      return isValid;
    });

    if (!templates) { return; }
    let group: any = {};

    templates.forEach(template => {
      let answers = template.Answers || [];
      let answerValue;
      let config = template.configJSON;
      if (config.dataType === QuestionDataType.CheckGroup) {
        // checkbox question answers are stored like: [[key, value], [key, value]]
        // checkgroup component takes an object like: { key: value, key: value }
        if (answers[0] && answers[0].answerJSON && answers[0].answerJSON.values) {
          const val = typeof answers[0].answerJSON.values[0] === 'string' ? [[answers[0].answerJSON.values[0], true]] : answers[0].answerJSON.values
          answerValue = Object.fromEntries(val);
        } else {
          answerValue = config.answers.reduce((p, c) => (p[c] = false, p), {});
        }
      } else {
        answerValue = answers.length > 0 && answers[0].answerJSON ? answers[0].answerJSON.values[0] : null;
      }

      template.answer = answerValue;
      template.formControlName = template.id;
      if (template.isRegistrationOptional) {
        template.displayQuestion += ' (optional)';
        group[template.formControlName] = new FormControl(answerValue);
      } else {
        const validator = config.dataType === QuestionDataType.CheckGroup ? FieldValidator.requiredCheckgroup : config.dataType === QuestionDataType.Grid ? FieldValidator.requiredGridQuestion : Validators.required
        group[template.formControlName] = new FormControl(answerValue, validator);
      }
    });
    this.templates = templates;
    this.form = this.formBuilder.group(group);
  }

  public valid() {
    return (this.form && this.form.valid) && (this.industryForm ? !this.industryForm.isEmpty() : true);
  }
}
